.frameChild,
.frameItem {
  position: absolute;
  top: -7px;
  left: -11px;
  border-radius: var(--br-81xl) 0 0 0;
  width: 242px;
  height: 242px;
  object-fit: cover;
}
.frameItem {
  top: 137px;
  left: 243px;
  border-radius: 0 var(--br-81xl) 0 0;
  width: 204px;
  height: 204px;
}
.frameChild1,
.frameChild2,
.frameChild3,
.frameInner,
.rectangleIcon {
  position: absolute;
  top: 233px;
  left: 79px;
  border-radius: 0 0 0 var(--br-31xl);
  width: 152px;
  height: 152px;
  object-fit: cover;
}
.frameChild1,
.frameChild2,
.frameChild3,
.rectangleIcon {
  top: 363px;
  left: 253px;
  border-radius: 0 0 var(--br-41xl) 0;
}
.frameChild1,
.frameChild2,
.frameChild3 {
  top: -7px;
  left: 383px;
  border-radius: 0 var(--br-31xl) 0 0;
  width: 144.91px;
}
.frameChild2,
.frameChild3 {
  top: 386px;
  left: 28px;
  border-radius: 0 0 0 var(--br-71xl);
  width: 164px;
  height: 164px;
}
.frameChild3 {
  top: 291px;
  left: 455px;
  border-radius: 0 0 var(--br-71xl) 0;
  width: 188px;
  height: 188px;
}
.rectangleParent {
  align-self: stretch;
  position: relative;
  height: 540px;
}
.outsideframe {
  flex: 1;
  height: 540px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.pacificFinancialProfessional,
.welcomeToThe {
  align-self: stretch;
  position: relative;
}
.welcomeToThe {
  font-size: var(--font-size-lg);
  line-height: 33px;
  font-weight: 500;
  font-family: var(--font-roboto);
  color: var(--color-darkslategray);
}
.pfpaintro {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-3xs) 0 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.joinUs {
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 45px;
  font-family: var(--font-roboto);
  color: var(--color-black);
  text-align: center;
}
.joinUsWrapper {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-181xl);
  background-color: var(--color-whitesmoke-200);
  border: 1px solid var(--color-black);
  box-sizing: border-box;
  width: 164px;
  height: 57px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-2xl) var(--padding-27xl);
  align-items: center;
  justify-content: center;
}
.joinusbuttonInner {
  position: relative;
  width: 164px;
  height: 57px;
}
.joinusbutton,
.pfpaintroParent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.joinusbutton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  align-items: flex-start;
}
.pfpaintroParent {
  align-self: stretch;
  padding: 0 var(--padding-3xs) var(--padding-xl) 0;
  align-items: center;
  gap: var(--gap-11xl);
}
.herosection,
.herosectionInner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.herosectionInner {
  flex: 1;
  flex-direction: column;
}
.herosection {
  align-self: stretch;
  background-color: var(--color-whitesmoke-100);
  flex-direction: row;
  padding: var(--padding-21xl) var(--padding-11xl);
  gap: var(--gap-21xl);
  text-align: left;
  font-size: var(--font-size-29xl);
  color: var(--color-dimgray-100);
  font-family: var(--font-changa-one);
}
@media screen and (max-width: 1200px) {
  .herosection {
    padding: var(--padding-61xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 960px) {
  .herosectionInner,
  .outsideframe {
    flex: unset;
    align-self: stretch;
  }
  .herosection {
    flex-direction: column;
    padding: var(--padding-41xl) var(--padding-21xl);
    box-sizing: border-box;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 430px) {
  .rectangleParent {
    object-fit: cover;
  }
  .outsideframe {
    width: auto;
    height: auto;
  }
  .herosectionInner {
    gap: var(--gap-11xl);
  }
}
