.summerCampBenefit {
  align-self: stretch;
  position: relative;
  font-weight: 600;
}
.thisPfpaSummer {
  margin-block-start: 0;
  margin-block-end: 20px;
}
.p {
  margin: 0;
}
.thisPfpaSummerContainer {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 135.2%;
  font-weight: 500;
}
.memberbenefittitle {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 0 var(--padding-31xl);
  align-items: center;
  justify-content: center;
  gap: var(--gap-3xs);
}
.courseDurationOneContainer {
  text-decoration: none;
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 135.2%;
  font-weight: 500;
  color: inherit;
}
.summercampsection {
  align-self: stretch;
  background-color: var(--color-whitesmoke-100);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-11xl) var(--padding-31xl);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-11xl);
}
.pacificBanking {
  font-size: var(--font-size-sm);
}
.copyrightInformation,
.membership {
  flex: 1;
  position: relative;
}
.membership {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  font-size: var(--font-size-sm);
  font-family: var(--font-roboto);
  color: var(--color-lightslategray);
  text-align: center;
  display: inline-block;
}
.footerLinks {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-31xl);
}
.footer,
.landingpage {
  display: flex;
  justify-content: center;
}
.footer {
  background-color: var(--color-white);
  width: 1440px;
  flex-direction: row;
  padding: var(--padding-13xl) var(--padding-41xl);
  box-sizing: border-box;
  align-items: flex-start;
  gap: var(--gap-31xl);
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-lightslategray);
}
.landingpage {
  position: relative;
  width: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: var(--font-size-13xl);
  color: var(--color-gray-100);
  font-family: var(--font-roboto);
}
@media screen and (max-width: 960px) {
  .copyrightInformation {
    text-align: center;
  }
}
@media screen and (max-width: 430px) {
  .summercampsection {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
  .copyrightInformation {
    text-align: center;
  }
  .copyrightInformation,
  .footerLinks,
  .membership {
    flex: unset;
    align-self: stretch;
  }
  .footerLinks {
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: center;
    justify-content: flex-start;
  }
  .footer {
    flex-direction: column;
    gap: var(--gap-xl);
    padding: var(--padding-3xs) var(--padding-xl);
    box-sizing: border-box;
  }
}
