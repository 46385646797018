.registrationsection {
  align-self: stretch;
  background-color: var(--color-mintcream);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: var(--padding-11xl) 200px;
  align-items: center;
  justify-content: center;
}
.pacificBanking {
  font-size: var(--font-size-sm);
}
.copyrightInformation,
.membership {
  flex: 1;
  position: relative;
}
.membership {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  font-size: var(--font-size-sm);
  font-family: var(--font-roboto);
  color: var(--color-lightslategray);
  text-align: center;
  display: inline-block;
}
.footerLinks {
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--gap-31xl);
}
.courseregistrationpage,
.footer,
.footerLinks {
  display: flex;
  align-items: flex-start;
}
.footer {
  background-color: var(--color-white);
  width: 1440px;
  flex-direction: row;
  padding: var(--padding-13xl) var(--padding-41xl);
  box-sizing: border-box;
  justify-content: center;
  gap: var(--gap-31xl);
}
.courseregistrationpage {
  position: relative;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-lightslategray);
  font-family: var(--font-roboto);
}
@media screen and (max-width: 960px) {
  .registrationsection {
    padding-left: var(--padding-3xs);
    padding-right: var(--padding-3xs);
    box-sizing: border-box;
  }
  .copyrightInformation {
    text-align: center;
  }
}
@media screen and (max-width: 430px) {
  .copyrightInformation {
    text-align: center;
  }
  .copyrightInformation,
  .footerLinks,
  .membership {
    flex: unset;
    align-self: stretch;
  }
  .footerLinks {
    flex-direction: column;
    gap: var(--gap-3xs);
    align-items: center;
    justify-content: flex-start;
  }
  .footer {
    flex-direction: column;
    gap: var(--gap-xl);
    padding: var(--padding-3xs) var(--padding-xl);
    box-sizing: border-box;
  }
}
