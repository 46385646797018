.pfpaLogoSmall1Icon {
  position: relative;
  width: 188px;
  height: 64px;
  object-fit: cover;
}
.aboutUs,
.home,
.membership,
.registration {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  font-size: var(--font-size-base);
  font-family: var(--font-roboto);
  text-align: center;
  display: inline-block;
}
.home {
  width: 12.26%;
  top: 0.03%;
  left: 0;
  color: var(--color-blueviolet-100);
}
.aboutUs,
.membership,
.registration {
  color: var(--color-dimgray);
}
.membership {
  width: 25.07%;
  top: 0.03%;
  left: 47.35%;
}
.aboutUs,
.registration {
  top: 0;
  left: 24.62%;
}
.aboutUs {
  width: 24.15%;
  top: 0.03%;
  left: 75.85%;
}
.headerLinks {
  position: relative;
  width: 409px;
  height: 19px;
}
.hamburgerBackground {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: var(--br-9xs);
}
.hamburgerIcon {
  position: absolute;
  top: 17px;
  left: 13.09px;
  width: 19.82px;
  height: 12px;
}
.headerHamburgerMenu {
  position: relative;
  width: 46px;
  height: 46px;
  display: none;
  cursor: pointer;
}
.seperator {
  position: relative;
  border-right: 1px solid var(--color-whitesmoke);
  box-sizing: border-box;
  width: 1px;
  height: 24.6px;
}
.vectorIcon {
  position: absolute;
  height: 108.53%;
  width: 108.54%;
  top: -5%;
  right: -3.54%;
  bottom: -3.53%;
  left: -5%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.headerSearchIcon {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: relative;
  width: 20px;
  height: 20px;
}
.headerMenuOptionsRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-lg);
}
.headerElementsRow,
.headerSection {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.headerSection {
  background-color: var(--color-white);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.04);
  height: 70px;
  flex-direction: column;
  padding: var(--padding-11xs) var(--padding-100xl);
  box-sizing: border-box;
  justify-content: center;
}
@media screen and (max-width: 1200px) {
  .headerHamburgerMenu {
    display: none;
  }
  .headerSection {
    padding-left: var(--padding-61xl);
    padding-right: var(--padding-61xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 960px) {
  .headerLinks {
    display: none;
  }
  .headerHamburgerMenu {
    display: flex;
    width: 46px;
    height: 46px;
  }
  .seperator {
    display: none;
  }
  .headerSection {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 420px) {
  .headerSection {
    padding-left: var(--padding-5xl);
    padding-right: var(--padding-5xl);
    box-sizing: border-box;
  }
}
