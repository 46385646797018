@keyframes slide-in-right {
  0% {
    transform: translateX(200px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
.menuChild {
  align-self: stretch;
  position: relative;
  border-top: 1px solid #e6e9f0;
  box-sizing: border-box;
  height: 1px;
}
.home {
  position: relative;
  font-size: var(--font-size-base);
  line-height: 24px;
  font-weight: 500;
  font-family: var(--font-roboto);
  color: var(--black);
  text-align: left;
  cursor: pointer;
}
.menuItem,
.menuItem3 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.menuItem {
  align-self: stretch;
  align-items: flex-start;
}
.menuItem3 {
  align-items: center;
}
.drawerMenuItems {
  justify-content: flex-start;
  gap: 28px;
}
.drawerMenu,
.drawerMenuItems,
.menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.menu {
  align-self: stretch;
  height: 299px;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.drawerMenu {
  position: relative;
  background-color: var(--color-white);
  width: 212px;
  height: 100%;
  padding: var(--padding-xl) var(--padding-11xl);
  box-sizing: border-box;
  justify-content: space-between;
  opacity: 0;
  max-width: 90%;
  overflow: auto;
}
.drawerMenu.animate {
  animation: 0.25s ease 0s 1 normal forwards slide-in-right;
}
