@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Changa+One:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-roboto: Roboto;
  --font-changa-one: "Changa One";
  --font-inter: Inter;
  --font-poppins: Poppins;

  /* font sizes */
  --font-size-base: 16px;
  --font-size-sm: 14px;
  --font-size-xl: 20px;
  --font-size-lg: 18px;
  --font-size-20xl: 39px;
  --font-size-17xl: 36px;
  --font-size-3xl: 22px;
  --font-size-13xl: 32px;
  --font-size-29xl: 48px;
  --font-size-5xl: 24px;

  /* Colors */
  --color-white: #fff;
  --color-gray-100: #1d2130;
  --color-gray-300: #141c3a;
  --black: #191919;
  --color-gray-400: #10162f;
  --color-lightslategray: #939eb4;
  --color-mintcream: #eff7f2;
  --color-black: #000;
  --color-darkslategray: #434343;
  --color-darkslategray-100: #36414d;
  --color-darkslategray-200: #2b3636;
  --color-whitesmoke-100: #f5f5f5;
  --color-whitesmoke-200: #f4f4f4;
  --color-whitesmoke: #eee;
  --color-dimgray-100: #626262;
  --color-dimgray: #495367;
  --color-dimgray-200: #4d4836;
  --color-blueviolet-100: #8a53ff;
  --color-blueviolet-200: #5922ce;
  --color-darkgray: #9c9c9c;
  --color-slategray-100: #576074;
  --color-red: #b4051a;

  /* Gaps */
  --gap-xl: 20px;
  --gap-31xl: 50px;
  --gap-3xs: 10px;
  --gap-29xl: 48px;
  --gap-5xl: 24px;
  --gap-10xs: 3px;
  --gap-11xl: 30px;
  --gap-5xs: 8px;
  --gap-21xl: 40px;
  --gap-lg: 18px;
  --gap-mid: 17px;
  --gap-7xs: 6px;

  /* Paddings */
  --padding-xl: 20px;
  --padding-11xl: 30px;
  --padding-13xl: 32px;
  --padding-41xl: 60px;
  --padding-3xs: 10px;
  --padding-5xl: 24px;
  --padding-100xl: 119px;
  --padding-51xl: 70px;
  --padding-21xl: 40px;
  --padding-61xl: 80px;
  --padding-6xl: 25px;
  --padding-12xs: 1px;
  --padding-4xs-4: 8.4px;
  --padding-12xs-6: 0.6px;
  --padding-31xl: 50px;
  --padding-4xs-7: 8.7px;
  --padding-2xl: 21px;
  --padding-27xl: 46px;
  --padding-7xs: 6px;
  --padding-57xl: 76px;
  --padding-81xl: 100px;
  --padding-7xl: 26px;
  --padding-11xs: 2px;
  --padding-9xl: 28px;
  --padding-base: 16px;

  /* border radiuses */
  --br-11xs: 2px;
  --br-13xl: 32px;
  --br-5xs: 8px;
  --br-181xl: 200px;
  --br-71xl: 90px;
  --br-31xl: 50px;
  --br-41xl: 60px;
  --br-81xl: 100px;
  --br-9xs: 4px;
  --br-xs: 12px;
}
