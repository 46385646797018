.pfpaSummerCamp {
  align-self: stretch;
  height: 36px;
  position: relative;
  font-size: var(--font-size-5xl);
  line-height: 36px;
  display: inline-block;
  font-family: var(--font-poppins);
  color: var(--color-red);
  text-align: left;
}
.courseformtitle {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: var(--color-darkslategray);
}
.input,
.participantName {
  align-self: stretch;
  font-family: var(--font-roboto);
}
.participantName {
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 30px;
  color: var(--color-slategray-100);
  text-align: left;
}
.input {
  border: 0;
  background-color: transparent;
  font-size: var(--font-size-base);
  color: var(--color-darkgray);
}
.firstnameinput {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.nameframe {
  gap: var(--gap-3xs);
}
.nameframe,
.parentnameframe {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.yourPhoneNumber {
  width: 176px;
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: 0.15px;
  line-height: 24px;
  font-family: var(--font-roboto);
  color: var(--color-darkgray);
  text-align: left;
  display: flex;
  align-items: center;
}
.phonenumbelabel {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.input4 {
  border: 0;
  background-color: transparent;
  flex: 1;
  font-family: var(--font-roboto);
  font-size: var(--font-size-base);
  color: var(--color-darkgray);
}
.phoneframe {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
}
.paymentMethod1 {
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-5xl);
  letter-spacing: 0.15px;
  line-height: 24px;
  font-weight: 600;
  color: var(--color-slategray-100);
  text-align: left;
}
.paymentMethod1,
.yourPayment {
  font-family: var(--font-roboto);
}
.yourPaymentOfContainer {
  position: relative;
  font-size: var(--font-size-5xl);
  letter-spacing: 0.15px;
  line-height: 24px;
  color: var(--color-slategray-100);
  white-space: pre-wrap;
  text-align: left;
}
.paymentamount,
.paypalbuttonIcon {
  align-self: stretch;
  overflow: hidden;
}
.paymentamount {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 var(--padding-20xl);
}
.paypalbuttonIcon {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  height: auto;
}
.paymentframe {
  align-self: stretch;
  border-radius: var(--br-8xs);
  background-color: var(--color-whitesmoke-200);
  border: 1px solid var(--color-silver);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs) 0 0 var(--padding-3xs);
  gap: var(--gap-3xs);
}
.zelleLogoSmall1Icon {
  width: 160px;
  position: relative;
  height: 106.5px;
  object-fit: cover;
}
.recipientNamePacific {
  flex: 1;
  position: relative;
  font-size: var(--font-size-5xl);
  letter-spacing: 0.15px;
  line-height: 24px;
  font-weight: 600;
  font-family: var(--font-roboto);
  color: var(--color-dimgray-100);
  text-align: left;
}
.zelleacct,
.zelleconfirmation1,
.zelleinstruction {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-3xs);
}
.zelleacct,
.zelleinstruction {
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.zelleinstruction {
  border-bottom: 1px solid var(--color-darkgray);
  flex-direction: column;
  padding: var(--padding-3xs);
  gap: var(--gap-xl);
}
.paypalMain {
  position: relative;
  max-width: 720px;
  min-width: 320px;
  color: var(--color-slategray-100);
  white-space: pre-wrap;
  
}
.step1Please {
  flex: 1;
  position: relative;
  font-size: var(--font-size-5xl);
  letter-spacing: 0.15px;
  line-height: 24px;
  font-weight: 600;
  font-family: var(--font-roboto);
  color: var(--color-slategray-100);
  text-align: left;
}
.paymentzelleframe {
  align-self: stretch;
  border-radius: var(--br-8xs);
  background-color: var(--color-whitesmoke-200);
  border: 1px solid var(--color-silver);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs) var(--padding-xl) var(--padding-xl)
    var(--padding-3xs);
  gap: var(--gap-xl);
}
.iHerebyGive {
  margin: 0;
}
.iHerebyGiveContainer {
  flex: 1;
  position: relative;
  font-size: var(--font-size-base);
  letter-spacing: 0.15px;
  line-height: 150%;
  font-family: var(--font-roboto);
  color: var(--color-black);
  white-space: pre-wrap;
  text-align: left;
}
.disclaimerframe,
.iagreeframe {
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.disclaimerframe {
  border-radius: var(--br-8xs);
  border: 1px solid var(--color-silver);
  padding: var(--padding-base) var(--padding-3xs);
}
.iagreeframe {
  padding: var(--padding-base) 0;
}
.notesinput {
  align-self: stretch;
  font-family: var(--font-roboto);
  font-size: var(--font-size-base);
  color: var(--color-darkgray);
}
.courseformframe {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.submit {
  position: absolute;
  width: calc(100% - 18.9px);
  top: 13.5px;
  left: 9.5px;
  font-size: var(--font-size-base);
  font-family: var(--font-roboto);
  color: var(--color-white);
  text-align: center;
  display: inline-block;
}
.formSubmitButton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: var(--color-blueviolet-100);
  width: 222px;
  position: relative;
  border-radius: var(--br-9xs);
  height: 46px;
}
.formSubmitButton:hover {
  background-color: var(--color-blueviolet-200);
}
.registrationform {
  margin: 0;
  align-self: stretch;
  border-radius: var(--br-xs);
  background-color: var(--color-white);
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.03);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-9xl) var(--padding-xl);
  gap: var(--gap-11xl);
}
@media screen and (max-width: 1200px) {
  .formSubmitButton {
    background-color: var(--color-blueviolet-100);
  }
}
@media screen and (max-width: 430px) {
  .yourPaymentOfContainer {
    font-size: var(--font-size-lg);
  }
  .recipientNamePacific {
    font-size: var(--font-size-sm);
  }
  .step1Please {
    font-size: var(--font-size-lg);
  }
  .registrationform {
    gap: var(--gap-31xl);
  }
}
@media screen and (max-width: 420px) {
  .firstnameinput {
    flex: unset;
    align-self: stretch;
  }
  .nameframe,
  .parentnameframe {
    flex-direction: column;
  }
  .input4,
  .phonenumbelabel {
    flex: unset;
    align-self: stretch;
  }
  .paymentframe,
  .paymentzelleframe,
  .phoneframe {
    flex-direction: column;
  }

}
