.time {
  align-self: stretch;
  position: relative;
  line-height: 36px;
}
.timesection {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: var(--padding-7xs) var(--padding-57xl);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
}
.thGradeOr {
  font-size: var(--font-size-xl);
}
.heading41,
.thGradeOr {
  align-self: stretch;
  position: relative;
  line-height: 24px;
}
.addMessageParticipantName,
.zelle {
  margin-block-start: 0;
  margin-block-end: 10px;
}
.addMessageParticipantName {
  color: var(--color-dimgray-200);
}
.pricesection,
.pricetitle {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: var(--padding-7xs) var(--padding-57xl);
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  font-size: var(--font-size-3xl);
  color: var(--color-darkslategray-100);
}
.pricesection {
  padding: 0 8.670013427734375px 0 var(--padding-xl);
  align-items: flex-start;
  font-size: var(--font-size-xl);
  color: var(--color-darkslategray-200);
}
.northPointCenter {
  margin: 0;
}
.joinUs {
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 45px;
  font-family: var(--font-roboto);
  color: var(--color-black);
  text-align: center;
}
.joinUsWrapper {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-181xl);
  background-color: var(--color-whitesmoke-200);
  border: 1px solid var(--color-black);
  box-sizing: border-box;
  width: 164px;
  height: 57px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-2xl) var(--padding-27xl);
  align-items: center;
  justify-content: center;
}
.registrationbuttonInner {
  position: relative;
  width: 164px;
  height: 57px;
}
.registrationbutton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.pfpaReservesThe {
  align-self: stretch;
  position: relative;
  line-height: 30px;
}
.disclaimerframe,
.sumercampdetail {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 0 8.670013427734375px 0 var(--padding-xl);
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-darkslategray-200);
}
.sumercampdetail {
  border-radius: var(--br-5xs);
  background-color: var(--color-whitesmoke-100);
  padding: var(--padding-3xs) var(--padding-11xl);
  align-items: center;
  gap: var(--gap-xl);
  text-align: center;
  font-size: var(--font-size-3xl);
  color: var(--color-darkslategray-100);
  font-family: var(--font-inter);
}
@media screen and (max-width: 430px) {
  .pricetitle,
  .timesection {
    padding-left: var(--padding-3xs);
    padding-right: var(--padding-3xs);
    box-sizing: border-box;
  }
}
