.discoverTheWorld {
  align-self: stretch;
  position: relative;
  line-height: 40px;
}
.b,
.discoverTheWorldHeader {
  display: flex;
  align-items: center;
}
.discoverTheWorldHeader {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
}
.b {
  position: absolute;
  top: 6.5px;
  left: 12.51px;
  line-height: 27px;
  justify-content: center;
  width: 7.15px;
  height: 18px;
}
.numberframe1 {
  position: relative;
  border-radius: var(--br-13xl);
  background-color: var(--color-gray-400);
  width: 32px;
  height: 32px;
}
.englishcontent1,
.englishtitle1 {
  align-self: stretch;
  position: relative;
  line-height: 22px;
}
.englishcontent1 {
  font-size: var(--font-size-sm);
}
.itemframe1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 8.3599853515625px 0.5900001525878906px 0;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-10xs);
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-gray-300);
}
.contentframe1,
.coursesyllabus,
.item1,
.itemcontent1,
.list {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.itemcontent1 {
  flex-direction: row;
  gap: var(--gap-5xl);
}
.contentframe1,
.coursesyllabus,
.item1,
.list {
  flex-direction: column;
}
.contentframe1 {
  border-radius: var(--br-11xs);
  padding: var(--padding-12xs);
}
.coursesyllabus,
.item1,
.list {
  background-color: var(--color-white);
}
.item1 {
  border: 1px solid var(--color-black);
  padding: var(--padding-5xl) var(--padding-6xl);
}
.coursesyllabus,
.list {
  font-size: var(--font-size-lg);
  color: var(--color-white);
  font-family: var(--font-roboto);
}
.coursesyllabus {
  background-color: var(--color-mintcream);
  padding: var(--padding-5xl) var(--padding-100xl) var(--padding-51xl);
  align-items: center;
  gap: var(--gap-29xl);
  text-align: center;
  font-size: var(--font-size-20xl);
  color: var(--color-darkslategray);
  font-family: var(--font-changa-one);
}
@media screen and (max-width: 1200px) {
  .coursesyllabus {
    padding: var(--padding-21xl) var(--padding-61xl) var(--padding-41xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 960px) {
  .coursesyllabus {
    padding-left: var(--padding-21xl);
    padding-right: var(--padding-21xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 420px) {
  .discoverTheWorld {
    font-size: var(--font-size-17xl);
  }
  .coursesyllabus {
    padding-left: var(--padding-5xl);
    padding-right: var(--padding-5xl);
    box-sizing: border-box;
  }
}
