.aboutusimagecontainerIcon {
  flex: 1;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
}
.pfpaSummerCamp {
  margin: 0;
}
.camptitle,
.welcomeToThe {
  align-self: stretch;
  position: relative;
}
.camptitle {
  font-size: var(--font-size-13xl);
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: var(--color-dimgray-100);
}
.welcomeToThe {
  line-height: 33px;
  font-weight: 500;
}
.introcontent,
.paragraph1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.paragraph1 {
  overflow: hidden;
  padding: var(--padding-7xl) 0;
}
.introcontent {
  gap: var(--gap-5xs);
}
.joinUs {
  position: relative;
  font-size: var(--font-size-lg);
  line-height: 45px;
  font-family: var(--font-roboto);
  color: var(--color-black);
  text-align: center;
}
.joinUsWrapper {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: var(--br-181xl);
  background-color: var(--color-whitesmoke-200);
  border: 1px solid var(--color-black);
  box-sizing: border-box;
  width: 164px;
  height: 57px;
  display: flex;
  flex-direction: row;
  padding: var(--padding-2xl) var(--padding-27xl);
  align-items: center;
  justify-content: center;
}
.registrationbuttonInner {
  position: relative;
  width: 164px;
  height: 57px;
}
.registrationbutton {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  flex-direction: column;
  align-items: center;
}
.aboutUs,
.registrationbutton,
.sumercampintrosection {
  display: flex;
  justify-content: flex-start;
}
.sumercampintrosection {
  flex: 1;
  flex-direction: column;
  padding: var(--padding-11xl) 0;
  align-items: center;
  gap: var(--gap-5xl);
}
.aboutUs {
  align-self: stretch;
  background-color: var(--color-mintcream);
  flex-direction: row;
  padding: var(--padding-31xl) var(--padding-81xl);
  align-items: flex-start;
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--color-darkslategray);
  font-family: var(--font-roboto);
}
@media screen and (max-width: 1200px) {
  .aboutusimagecontainerIcon {
    padding-right: 0;
    box-sizing: border-box;
  }
  .aboutUs {
    padding: var(--padding-61xl);
    box-sizing: border-box;
  }
}
@media screen and (max-width: 960px) {
  .aboutusimagecontainerIcon,
  .sumercampintrosection {
    box-sizing: border-box;
    flex: unset;
    align-self: stretch;
  }
  .aboutusimagecontainerIcon {
    padding-right: 0;
  }
  .sumercampintrosection {
    padding: var(--padding-5xl) 0;
  }
  .aboutUs {
    flex-direction: column;
    padding: var(--padding-41xl) var(--padding-21xl);
    box-sizing: border-box;
  }
}
